<template>
    <div class="parent">
        <label class="titre" for="Liste Formations">{{ titre }}</label>
        <div class="contanire">
            <div class="main-video">
                <video :src="video" loop controls></video>
            </div>
            <div class="main-list" >
                <ul v-for="item in lecon" :key="item">
                    <h1>{{ item.lecon }}</h1>
                    <li v-for="p in item.programme" :key="p">
                        <h2>{{ p }}</h2>
                        <ul v-for="m in item.medias" :key="m">
                            <li @click="selectMedias(m)">- {{ m }}</li>
                        </ul>
                    </li>
                </ul>

            </div>
        </div>

    </div>
</template>
    
<script>

import MesFormations from "@/connection/MesFormations";

export default {

    name: "VideoPlaylist",
    data() {
        return {
            titre : null,
            lecon : [],
            video : "https://medias-formations.fra1.cdn.digitaloceanspaces.com/Interdit.mp4"
        }
    },

    props : {
        mesFormationId : String
    },

    methods : {
        selectMedias(value) {
            
            MesFormations.selectMedias(value)
            .then((result) => {
                console.log(result.data)
                this.video = "";
                this.video = result.data.video;
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },

    components: {
    },

    created() {

        MesFormations.select(this.mesFormationId)
        .then((result) => {

            // set titre formation
            console.log(result.data.mesFormation)
            
            this.titre = result.data.mesFormation.titre
            for(let i = 0; i < result.data.mesFormation.schemaLecon.length; i++) {
                // set titre lecon 
                this.lecon.push({
                    lecon : result.data.mesFormation.schemaLecon[i].titre,
                    programme : [],
                    medias : []
                });


                for(let y = 0; y < result.data.mesFormation.schemaProgramme[i].length; y++) {
                    this.lecon[i].programme.push(result.data.mesFormation.schemaProgramme[i][y][0]["titre"]);

                    for(let z = 0; z < result.data.mesFormation.schemaProgramme[i][y][0]["medias"].length; z++) {
                    this.lecon[i].medias.push(result.data.mesFormation.schemaProgramme[i][y][0]["medias"][z]);
                }
                }

            }

            // console.log(lecon)

        })
        .catch((error) => {
            console.log(error);
        });
    }
}
</script>
    
<style scoped>
.parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
}

.titre {
    font-size: larger;

}

.contanire {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    
}

.main-video {
    background-color: white;
    width: 70%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-list {
    padding: 15px 15px 15px 15px;
    border-left: 2px solid #eee;
    background-color: #eee;
    width: 30%;
    overflow: auto;
    display: flex;
    flex-direction: column;

}

.main-list ul {
    background-color: white;
    margin-top: 2px;
    margin-bottom: 2px;
}

.main-list ul h1 {
    font-size: large;
    font-weight: 800;
    background-color: rgba(0, 0, 0, 0.63);
    color: #ddd;
}
.main-list ul li h2 {
    background-color: rgba(117, 12, 238, 0.63);
}

.main-list ul li {
    margin-left: 20px;
    cursor: pointer;
}

.main-video video {
    height: 100%;
    padding: 10px;
}




</style>
